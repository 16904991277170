<template>
    <div class="scene scene--card" v-if="false">
      <div
          class="card"
          @click="cardOne == 'start' ? (cardOne = 'flipped' ) : (cardOne = 'start' )"
          v-bind:class="{ flipme: cardOne == 'flipped' }"
      >
        <div class="card__face card__face--front" style="display: table">
          <div class="_bg-blue" style="display: table-cell; vertical-align: middle;">
            <q-img :src="file" style="border-radius: 10px;"/>
          </div>
        </div>
        <div class="card__face card__face--back" style="display: table">
          <div class="_bg-blue" style="display: table-cell; vertical-align: middle;">
            <h6 style="font-weight: bold">{{lawCard.data.term}}</h6>
            <q-scroll-area style="height: 130px; _max-width: 300px;" class="q-ma-sm">
          {{lawCard.data.def}}
            </q-scroll-area>
            <q-btn round icon="edit" dense size="sm" color="blue" @click="lawCardFormShow=true"></q-btn>
          </div>
        </div>
      </div>
    </div>
  <q-card style="_max-width: 300px; margin: 10px; border-radius: 20px" @click="backsideShow=true">
<!--    <q-img :src="file" style="border-radius: 10px;"/>-->
    <q-img :src="file !== undefined ? file : 'https://cdn.quasar.dev/img/parallax2.jpg'" style="min-width: 350px">
      <div class="absolute-bottom text-subtitle2 text-center" style="line-height: 1.0">
<!--        {{lawCard.data.term}}-->
        join us on Facebook and Instagram! @law.english.pro
      </div>
    </q-img>
  </q-card>
  <q-dialog v-model="lawCardFormShow">
    <law-card-form @closed="lawCardFormShow=false" :law-card="lawCard"/>
  </q-dialog>
  <q-dialog v-model="backsideShow" maximized>
    <q-card >
      <q-card-section class="fit row wrap justify-center items-start content-start">
        <q-card style="max-width: 700px" flat>
          <q-bar class="bg-white">
          </q-bar>
          <q-card-section class="text-center" >
            <p style="font-weight: bold; font-size: 24px" class="text-center">{{lawCard.data.term}}</p>
            <!--        <q-scroll-area style="height: 330px; _max-width: 300px;" class="q-ma-sm text-justify" >-->
            <div class="def text-justify" v-html="lawCard.data.def"/>
            <!--          {{lawCard.data.def}}-->
            <!--        </q-scroll-area>-->
            <q-btn round icon="edit" dense size="sm" color="blue" @click="lawCardFormShow=true" v-if="isAdmin"></q-btn>
            <br/>
          </q-card-section>
          <q-card-section class="text-center">
            <q-btn color="green" @click="backsideShow=false">Close</q-btn>
            <br/>
          </q-card-section>
          <q-card-section class="text-center">
            <law-card-user-notes :lawCard="lawCard" />

          </q-card-section>
        </q-card>
      </q-card-section>
    </q-card>
  </q-dialog>

</template>

<script>
import LawCardForm from "@/components/law-card-form";
import LawCardUserNotes from "@/components/law-card-user-notes";

export default {
  name: "law-card",
  components: {LawCardUserNotes, LawCardForm},
  props: ["lawCard"],
  data() {
    return {
      cardOne: "start",
      lawCardFormShow: false,
      file: "",
      backsideShow: false,

    };
  },
  created() {
    this.getImg()
  },
  computed: {
    isAdmin(){
      if(this.$store.state.user.phoneNumber === "+79265576190"){
        return true
      }
      else{
        return false
      }
    }
  },
  methods: {
    al(){
      alert(1)
    },
    getImg(){
      const that = this
      this.file = this.$store.dispatch("frontImgGet", this.lawCard.id)
          .then((file)=>{
            console.log(file);
            that.file = file
          })
          .catch(()=>{
            that.file = undefined
          })
    }
  }
}
</script>

<style scoped>
body {
  font-family: sans-serif;
}
.def ::v-deep blockquote{
  background-color: lemonchiffon;
  border-left: 4px solid orange;
  padding: 10px;
}

.scene {
  width: 370px;
  height: 230px;
  border: 0px solid #ccc;
  margin: 10px;
  perspective: 600px;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 1.1;
  color: white;
  text-align: center;

  font-size: 22px;
  backface-visibility: hidden;
  border-radius: 10px;
}

.card__face--front {
  background: #3ba23b;
  font-weight: bold;
  _padding: 3px
}

.card__face--back {
  background: gainsboro;
  transform: rotateY(180deg);
  color: #1D1D1D;
  font-size: 14px;
}

/* this style is applied when the card is clicked */
.flipme {
  transform: rotateY(180deg);
}
</style>
