<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="bg-white text-green" style="_height: 60px">
      <q-toolbar class="justify-center text-center" @click="userAuthShow=!userAuthShow">
        <q-space/>
        <q-avatar alt="Vue logo" size="45px">
          <img src="@/assets/logo2.png"/>
        </q-avatar>
        <q-toolbar-title >
          <div style="margin-top: 0px; font-size: 24px; font-weight: bold">
            law terms
          </div>
          <div style="margin-top: -8px; font-size: 10px; font-weight: bold; color: gray; letter-spacing: 3px">
            LEGAL ENGLISH
          </div>

<!--          <span @click="lawCardsListShow=!lawCardsListShow"></span>-->
        </q-toolbar-title>
        <q-space/>
<!--        <q-avatar alt="Vue logo" size="45px" icon="person" @click="userAuthShow=!userAuthShow">-->
<!--        </q-avatar>-->
      </q-toolbar>
    </q-header>


    <q-page-container class="flex flex-center">
      <law-cards style="max-width: 800px" />
    </q-page-container>
  </q-layout>
  <q-dialog v-model="userAuthShow">
    <user-auth @closed="userAuthShow=false"/>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'

import LawCards from "@/components/law-cards";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UserAuth from "@/components/user-auth";
const auth = getAuth();

export default {
  name: 'LayoutDefault',

  components: {
    UserAuth,
    LawCards
  },
  data: ()=>{
    return{
      lawCardsListShow: false,
      userAuthShow: false
    }
  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  },
  created() {
    this.$store.dispatch("lawCardListener")
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.commit("userSet", user)
        this.$store.dispatch("userNotesListener", user.phoneNumber)
        if(user.phoneNumber === "+79265576190"){
          this.$store.dispatch("lawCardHiddenListener")
        }
      } else {
        this.$store.commit("userUnset")
        this.$store.commit("userNotesDelAll")
      }
    });
  }
}
</script>
<style scoped>
</style>
