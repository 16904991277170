<template>
  <q-card class="q-pa-md" v-show="$store.state.user.phoneNumber === undefined">
    <q-input v-model="phone" label="phone" :disable="confirmationResult!==null"/>
    <q-input v-model="code" label="code" v-if="confirmationResult!==null"/>
    <div id="recaptcha-container" v-if="confirmationResult===null"></div>
    <q-card-actions>
      <q-btn label="Cancel" @click="$emit('closed')"/>
      <q-btn label="Send code" @click="send()" v-if="confirmationResult===null"/>
      <q-btn label="Verify code" @click="verify()" v-if="confirmationResult!==null"/>
    </q-card-actions>
  </q-card>
  <q-card class="q-pa-md" v-show="$store.state.user.phoneNumber !== undefined">

    <q-card-section class="text-center">
      {{$store.state.user.phoneNumber}}
    </q-card-section>
    <q-card-section class="text-center" v-if="$store.state.user.phoneNumber === '+79265576190'">
      <q-btn label="law cards list" @click="lawCardsListShow=true"/>
    </q-card-section>
    <q-card-actions>
      <q-btn label="Cancel" @click="$emit('closed')"/>
      <q-btn label="logout" @click="logout()"/>
    </q-card-actions>
  </q-card>
  <q-dialog v-model="lawCardsListShow">
    <law-cards-list/>
  </q-dialog>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAsxemEeXUk_iF1vKUTot_vS3cCu1q3wYo",
  authDomain: "law-cards.firebaseapp.com",
  projectId: "law-cards",
  storageBucket: "law-cards.appspot.com",
  messagingSenderId: "264702178358",
  appId: "1:264702178358:web:d7ed5c2f3bb505298b9497",
  measurementId: "G-JKZ7CYQ5B3"
};

// Initialize Firebase
initializeApp(firebaseConfig);


//, PhoneAuthProvider
const auth = getAuth();
import LawCardsList from "@/components/law-cards-list";

export default {
  name: "user-auth",
  components: {
    LawCardsList
  },
  data: ()=>{
    return{
      phone: "",
      code: "",
      recaptchaVerifier: "",
      confirmationResult: null,
      lawCardsListShow: false
    }
  },
  mounted() {
    this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
  },
  computed: {
    userPhone(){
      return this.$store.state.user.phoneNumber
    }
  },
  watch: {
    userPhone(newVal, oldVal){
      if(oldVal === undefined && newVal !== null){
        this.$emit('closed')
      }
    }
  },
  methods: {
    send(){
      let that = this
      signInWithPhoneNumber(auth, this.phone, this.recaptchaVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            that.confirmationResult = confirmationResult;
            // ...
          }).catch((error) => {
        // Error; SMS not sent
        // ...
        console.log(error)
      });
    },
    verify(){
        this.confirmationResult.confirm(this.code).then((result) => {
          // User signed in successfully.
          // const user = result.user;
          // this.$store.commit("userUpdate", result.user)
          // ...
          console.log(result)
          this.$emit('closed')
        }).catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          console.log(error)
        });

    },
    logout(){
      signOut(auth).then(() => {
        // Sign-out successful.
        this.$emit('closed')
      }).catch((error) => {
        // An error happened.
        console.log(error)
      });
    },
  }
}
</script>

<style scoped>

</style>