import { createStore } from 'vuex'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { collection, query, onSnapshot, addDoc, setDoc, doc, deleteDoc, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsxemEeXUk_iF1vKUTot_vS3cCu1q3wYo",
  authDomain: "law-cards.firebaseapp.com",
  projectId: "law-cards",
  storageBucket: "law-cards.appspot.com",
  messagingSenderId: "264702178358",
  appId: "1:264702178358:web:d7ed5c2f3bb505298b9497",
  measurementId: "G-JKZ7CYQ5B3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export default createStore({
  state: {
    lawCards: [],
    user: {},
    userNotes: []
  },
  mutations: {
    lawCardAdd(state, lawCard){
      state.lawCards.push(lawCard)
    },
    lawCardModify(state, lawCard){
      let i = state.lawCards.findIndex(el=>el.id===lawCard.id)
      state.lawCards.splice(i, 1, lawCard)
    },
    lawCardDel(state, lawCardId){
      let i = state.lawCards.findIndex(el=>el.id===lawCardId)
      state.lawCards.splice(i, 1)
    },

    //userNote
    userNoteAdd(state, userNote){
      state.userNotes.push(userNote)
    },
    userNoteModify(state, userNote){
      let i = state.userNotes.findIndex(el=>el.id===userNote.id)
      state.userNotes.splice(i, 1, userNote)
    },
    userNoteDel(state, userNoteId){
      let i = state.userNotes.findIndex(el=>el.id===userNoteId)
      state.userNotes.splice(i, 1)
    },
    userNotesDelAll(state) {
      state.userNotes = []
    },

    //user
    userSet(state, user){
      state.user = user
    },
    userUnset(state){
      state.user = {}
    }
  },
  actions: {
    lawCardListener(context){
      const q = query(collection(db, "law-cards"), where("status", "==", "visible"));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            context.commit("lawCardAdd", {id: change.doc.id, data: change.doc.data()})
          }
          if (change.type === "modified") {
            context.commit("lawCardModify", {id: change.doc.id, data: change.doc.data()})

          }
          if (change.type === "removed") {
            context.commit("lawCardDel", change.doc.id)
          }
        });
      });
    },
    lawCardHiddenListener(context){
      const q = query(collection(db, "law-cards"), where("status", "==", "hidden"));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            context.commit("lawCardAdd", {id: change.doc.id, data: change.doc.data()})
          }
          if (change.type === "modified") {
            context.commit("lawCardModify", {id: change.doc.id, data: change.doc.data()})

          }
          if (change.type === "removed") {
            context.commit("lawCardDel", change.doc.id)
          }
        });
      });
    },
    userNotesListener(context, userId){
      const q = query(collection(db, "user-notes"), where("userId", "==", userId));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            context.commit("userNoteAdd", {id: change.doc.id, data: change.doc.data()})
          }
          if (change.type === "modified") {
            context.commit("userNoteModify", {id: change.doc.id, data: change.doc.data()})

          }
          if (change.type === "removed") {
            context.commit("userNoteDel", change.doc.id)
          }
        });
      });
    },
    lawCardAdd(context, lawCardData){
      addDoc(collection(db, "law-cards"), lawCardData)
    },
    lawCardChange(context, lawCard){
      setDoc(doc(db, "law-cards", lawCard.id), lawCard.data);
    },
    lawCardDel(context, lawCardId){
      deleteDoc(doc(db, "law-cards", lawCardId));
    },

    userNoteSave(context, userNote){
      if(userNote.id === ""){
        addDoc(collection(db, "user-notes"), userNote.data)
      }
      else{
        setDoc(doc(db, "user-notes", userNote.id), userNote.data);
      }

    },
    userNoteDel(context, userNoteId){
      deleteDoc(doc(db, "user-notes", userNoteId));
    },

    frontImgUpload(context, pl){
      const storageRef = ref(storage, "frontImgs/"+pl.lawCardId);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, pl.file).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot);
      });
    },
    frontImgGet(context, lawCardId){
      return new Promise((resolve, reject) => {
        const storageRef = ref(storage, "frontImgs/"+lawCardId);
        getDownloadURL(storageRef)
            .then((snapshot) => {
              console.log('downloaded a blob or file!');
              console.log(snapshot);
              resolve(snapshot)
            })
            .catch(()=>{
              reject(undefined)
            })
      });
    }
  },
  modules: {
  }
})
