<template>
  <q-card flat>
    <q-card-section class="fit row wrap justify-center items-start content-start">
      <q-btn class="q-ma-xs" round
             v-for="firstChar in firstChars"
             :key="firstChar"
             :color="firstCharSelected===firstChar?'orange':'white'"
             @click="firstCharSelected===firstChar?firstCharSelected='':firstCharSelected=firstChar"
      >
        <span :style="firstCharSelected===firstChar?'color: white':'color: grey'">{{firstChar}}</span>
      </q-btn>
    </q-card-section>
    <q-card-section class="fit row wrap justify-center items-start content-start">
      <law-card v-for="lawCard in lawCardsToShow" :key="lawCard.id" :lawCard="lawCard"/>
    </q-card-section>
    <q-card-section class="fit row wrap justify-center items-start content-start" v-if="$store.state.user.phoneNumber === '+79265576190'">
      <q-btn round icon="add" dense color="blue" @click="lawCardFormShow=true"></q-btn>
    </q-card-section>
  </q-card>
<!--  <q-page >-->
<!--    -->
<!--  </q-page>-->

  <q-card style="height: 50px"></q-card>
  <q-dialog v-model="lawCardFormShow">
    <law-card-form @closed="lawCardFormShow=false"/>
  </q-dialog>
</template>

<script>
import LawCard from "@/components/law-card";
import LawCardForm from "@/components/law-card-form";
export default {
  name: 'HelloWorld',
  components: {LawCardForm, LawCard},
  data() {
    return {
      cardOne: "start",
      lawCardFormShow: false,
      firstCharSelected: ""
    };
  },
  computed: {
    lawCards(){
      let lawCards = this.$store.state.lawCards
      lawCards.sort((a, b) => {
        let fa = a.data.term.toLowerCase(),
            fb = b.data.term.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
      return lawCards
    },
    lawCardsToShow(){
      let lawCardsToShow = []
      let that = this
      if(this.firstCharSelected!==''){
        this.$store.state.lawCards.forEach(el=>{
          if(el.data.term.charAt(0)===that.firstCharSelected) lawCardsToShow.push(el)
        })
      }
      else{
        lawCardsToShow = this.$store.state.lawCards
      }
      return lawCardsToShow
    },
    firstChars(){
      let firstChars = []
      this.lawCards.forEach(el=>{
        if(firstChars.findIndex(fChar=>fChar===el.data.term.charAt(0))===-1) firstChars.push(el.data.term.charAt(0))
      })
      firstChars.sort()
      return firstChars
    }
  }
}
</script>

