<template>
    <q-card-section>
      <q-file v-model="file" label="Image file" dense>
      </q-file>
    </q-card-section>
  <q-card-section class="text-center">
    <q-btn dense @click="uploadImg()">upload</q-btn>
  </q-card-section>
  <q-card-section class="text-center">
    <q-img :src="file" style="height: 50px; max-width: 50px"/>
  </q-card-section>
</template>

<script>
export default {
  name: "law-card-form-img",
  props: ["lawCard"],
  data: () => {
    return {
      file: ""
    }
  },
  created() {
    this.formDataChild = this.lawCard
    this.getImg()
  },
  methods: {
    statusChange(){
      if (this.formDataChild.status === 'hidden'){
        this.formDataChild.status = 'visible'
      }
      else{
        this.formDataChild.status = 'hidden'
      }
    },
    uploadImg(){
      const that = this
      this.$store.dispatch("frontImgUpload", {lawCardId: this.lawCard.id, file: this.file})
          .then(()=>{
            that.getImg()
          })
    },
    getImg(){

      const that = this
      this.file = this.$store.dispatch("frontImgGet", this.lawCard.id)
          .then((file)=>{
            console.log(file);
            that.file = file
          })
    }
  }
}
</script>

<style scoped>

</style>