<template>
    <q-card-section>
      <q-input v-model="formDataChild.term" label="term" color="green" :rules="[val => !!val || 'Field is required']"/>
<!--      <q-input v-model="formDataChild.def" type="textarea" label="definition" filled color="green" :rules="[val => !!val || 'Field is required']"/>-->
      <q-editor
          v-model="formDataChild.def"
          dense :toolbar="[['bold', 'italic', 'strike', 'underline'], ['quote', 'unordered', 'ordered']]"
          />
      <q-chip :color="formDataChild.status==='visible'?'green':'grey'" class="text-white" clickable @click="statusChange">{{ formDataChild.status }}</q-chip>
    </q-card-section>
</template>

<script>
export default {
  name: "law-card-form-general",
  props: ["lawCard"],
  data: () => {
    return {
      formDataChild: {}
    }
  },
  created() {
    this.formDataChild = this.lawCard
  },
  methods: {
    statusChange(){
      if (this.formDataChild.status === 'hidden'){
        this.formDataChild.status = 'visible'
      }
      else{
        this.formDataChild.status = 'hidden'
      }
    }
  }
}
</script>

<style scoped>

</style>