<template>
<q-card style="max-width: 300px; max-height: 400px">
  <q-card-section class="text-center">
    List of cards
  </q-card-section>
  <q-card-section>
    <q-list separator >
      <law-cards-list-item v-for="lawCard in lawCards" :key="lawCard.id" :lawCard="lawCard"/>
    </q-list>
  </q-card-section>
  <q-card-section class="text-center">
    <q-btn icon="add" round dense size="sm" @click="lawCardFormShow=true"></q-btn>
  </q-card-section>
  <q-dialog v-model="lawCardFormShow">
    <law-card-form @closed="lawCardFormShow=false"/>
  </q-dialog>
</q-card>
</template>

<script>
import LawCardsListItem from "@/components/law-cards-list-item";
import LawCardForm from "@/components/law-card-form";
export default {
  name: "law-card-list",
  components: {LawCardForm, LawCardsListItem},
  data: ()=> {
    return{
      lawCardFormShow: false
    }
  },
  computed: {
    lawCards(){
      return this.$store.state.lawCards
    }
  }
}
</script>

<style scoped>

</style>