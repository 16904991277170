<template>
  <q-item>
    <q-item-section>
      <q-item-label>
        {{lawCard.data.term}}
        <q-chip dense size="sm" :color="lawCard.data.status==='visible'?'green':'grey'" class="text-white">{{lawCard.data.status}}</q-chip>
      </q-item-label>
      <q-item-label caption lines="2">{{lawCard.data.def}}</q-item-label>
    </q-item-section>

    <q-item-section side top>
      <q-btn icon="edit" round dense size="sm" @click="lawCardFormShow=true"></q-btn>
    </q-item-section>
  </q-item>
  <q-dialog v-model="lawCardFormShow">
    <law-card-form @closed="lawCardFormShow=false" :law-card="lawCard"/>
  </q-dialog>
</template>

<script>
import LawCardForm from "@/components/law-card-form";
export default {
  name: "law-cards-list-item",
  components: {LawCardForm},
  props: ["lawCard"],
  data: ()=> {
    return {
      lawCardFormShow: false
    }
  }
}
</script>

<style scoped>

</style>