<template>
  <q-card>
    <q-card-section class="text-center text-h6">
      term form
    </q-card-section>
    <q-tabs
        v-model="tab"
        no-caps
        _class="bg-orange text-white shadow-2"
        dense
        align="center"
        narrow-indicator
        :breakpoint="0"
    >
      <q-tab name="general" label="General"/>
      <q-tab name="img" label="Img"/>
    </q-tabs>
    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="general">
        <law-card-form-general :lawCard="formData"/>
      </q-tab-panel>
      <q-tab-panel name="img">
        <law-card-form-img :lawCard="formData"/>
      </q-tab-panel>
    </q-tab-panels>
    <q-card-section>
      <q-btn @click="delConfirmShow=true" flat color="green">Delete</q-btn>
      <q-btn @click="cancel" flat color="green">Cancel</q-btn>
      <q-btn @click="save" flat color="green">Save</q-btn>
    </q-card-section>
  </q-card>
  <q-dialog v-model="delConfirmShow">
    <q-card>
      <q-card-section>
        Do you really want to delete term?
      </q-card-section>
      <q-card-actions>
        <q-space/>
        <q-btn @click="delConfirmShow=false">No</q-btn>
        <q-btn @click="del">Yes</q-btn>
        <q-space/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import LawCardFormGeneral from "@/components/law-card-form-general";
import LawCardFormImg from "@/components/law-card-form-img";
export default {
  name: "law-card-form",
  components: {LawCardFormImg, LawCardFormGeneral},
  props: ["lawCard"],
  data: () => {
    return {
      delConfirmShow: false,
      formData: {
        term: "",
        def: "",
        status: "visible"
      },
      tab: 'general'
    }
  },
  created() {
    if (this.lawCard !== undefined) {
      this.formData.id = this.lawCard.id
      this.formData.term = this.lawCard.data.term
      this.formData.def = this.lawCard.data.def
      this.formData.status = this.lawCard.data.status
    }
  },
  methods: {
    statusChange(){
      if (this.formData.status === 'hidden'){
        this.formData.status = 'visible'
      }
      else{
        this.formData.status = 'hidden'
      }
    },
    cancel(){
      this.$emit("closed")
    },
    save(){
      if(this.lawCard === undefined){
        this.$store.dispatch("lawCardAdd", this.formData)
      }
      if(this.lawCard !== undefined){
        this.$store.dispatch("lawCardChange", {id: this.lawCard.id, data: this.formData})
      }
      this.$emit("closed")
    },
    del(){
      if(this.lawCard !== undefined){
        this.$store.dispatch("lawCardDel", this.lawCard.id)
      }
      this.$emit("closed")
    }
  }
}
</script>

<style scoped>

</style>