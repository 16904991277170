<template>
<q-card>
  <q-card-section>
    <span style="font-weight: bold">My notes</span>
  </q-card-section>
  <q-card-section v-if="$store.state.user.phoneNumber !== undefined">
    <q-list separator>
      <q-item v-for="userNote in userNotes" :key="userNote.id">
        <q-item-section class="text-left">
          <div style="font-size: 11px; color: grey; font-weight: bold" class="q-pl-xs">
            {{new Date(userNote.data.TS).toISOString().substring(0,10)}}
          </div>
          <div v-html="userNote.data.note"></div>
        </q-item-section>
        <q-item-section side top>
          <q-btn icon="edit" color="grey" round size="sm" dense @click="edit(userNote)"/>
        </q-item-section>
      </q-item>
    </q-list>

    <q-btn round dense icon="add" color="grey" @click="add()"/>
  </q-card-section>
  <q-card-section v-if="$store.state.user.phoneNumber === undefined">
    <q-btn color="orange" @click="userAuthShow=true">Login</q-btn>
  </q-card-section>
</q-card>
  <q-dialog v-model="userNoteFormShow">
    <q-card>
      <q-card-section>
        <q-editor
            v-model="noteForm"
            dense :toolbar="[['bold', 'italic', 'strike', 'underline'], ['unordered', 'ordered']]"
        />
      </q-card-section>
      <q-card-actions>
        <q-btn @click="delConfirmShow=true">delete</q-btn>
        <q-btn @click="userNoteFormShow=false">cancel</q-btn>
        <q-btn @click="save()">save</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="delConfirmShow">
    <q-card>
      <q-card-section>
        Do you really want to delete note?
      </q-card-section>
      <q-card-actions class="text-center">
        <q-btn @click="delConfirmShow=false">No</q-btn>
        <q-btn @click="del()">Yes</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="userAuthShow">
    <user-auth/>
  </q-dialog>
</template>

<script>
import UserAuth from "@/components/user-auth";
export default {
  name: "law-card-user-notes",
  components: {UserAuth},
  props: ["lawCard"],
  data: ()=>{
    return {
      userNoteFormShow: false,
      delConfirmShow: false,
      noteForm: "",
      userNoteIdForm: "",
      userAuthShow: false

    }
  },
  computed: {
    userNotes(){
      let that = this
      return this.$store.state.userNotes.filter(el=>el.data.lawCardId === that.lawCard.id)
    },
    user(){
      return this.$store.state.user
    }
  },
  watch: {
    user(newVal, oldVal){
      if(oldVal.phoneNumber === undefined && newVal.phoneNumber !== undefined){
        this.userAuthShow = false
      }
    }
  },
  methods: {
    add(){
      this.userNoteIdForm = ""
      this.noteForm = ""
      this.userNoteFormShow = true
    },
    save(){
      let userNoteData = {
        id: this.userNoteIdForm,
        data: {
          TS: Date.now(),
          note: this.noteForm,
          userId: this.$store.state.user.phoneNumber,
          lawCardId: this.lawCard.id
        }
      }
      this.$store.dispatch("userNoteSave", userNoteData)
      this.userNoteFormShow = false
    },
    edit(userNote){
      this.userNoteIdForm = userNote.id
      this.noteForm = userNote.data.note
      this.userNoteFormShow = true
    },
    del(){
      this.delConfirmShow = false
      this.userNoteFormShow = false
      this.$store.dispatch("userNoteDel", this.userNoteIdForm)
    }
  }
}
</script>

<style scoped>

</style>